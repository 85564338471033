<template>
  <div id="mapCa" style="height: 260px; width: 100%; margin:auto"></div>
</template>

<script>
import L from 'leaflet';

export default {
  mounted() {
    setTimeout(() => {
      this.map = L.map('mapCa').setView([-12.027701131655201, -77.10345223205543], 16);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
        maxZoom: 20,
      }).addTo(this.map);

      const myIcon = L.icon({
      // eslint-disable-next-line global-require
        iconUrl: require('../../../assets/marker.png'),
        iconSize: [18, 18],
      });

      const markers = [
        { latitud: -12.027701131655201, longitud: -77.10345223205543, nombre: 'Lima Cargo' },
        { latitud: -12.051474691464117, longitud: -77.08589184554866, nombre: 'Mall' },
      ];

      markers.forEach((markerData) => {
        const marker = L.marker([markerData.latitud, markerData.longitud], { icon: myIcon })
          .addTo(this.map);
        marker.bindPopup(markerData.nombre);
      });
    }, 2000);
  },
};
</script>

<style>
#map {
  height: 100%;
  width: 100%;
}
</style>
